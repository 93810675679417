<template>
  <div class="readme-article">
    <h2 id="好友欢迎语">3.好友欢迎语</h2>
    <h3 id="欢迎语列表">3.1欢迎语列表</h3>
    <p>查看欢迎语列表，包括消息内容、使用员工、创建时间等信息。</p>
    <p>
      <img src="@/assets/img/scrm/3-7.png" />
    </p>
    <h3 id="欢迎语编辑">3.2欢迎语编辑</h3>
    <p>欢迎语新建编辑，文字欢迎语可插入客户昵称，会根据客户名称自动替换。</p>
    <p>
      <img src="@/assets/img/scrm/3-8.png" />
    </p>
  </div>
</template>

<script>
export default {
  name: "Scrm3-3",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>